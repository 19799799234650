import { ModuleWithProviders, NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RouterModule, Routes } from '@angular/router';
import { AuthComponent } from './auth.component';
import { AuthGuard } from '../../core/_auth/_guard/auth.guard';
import { HomePageComponent } from './home-page/home-page.component';
import { CarouselModule } from 'ngx-owl-carousel-o';
import { ThemeModule } from '../theme/theme.module';
import { HomeBannerComponent } from './home-banner/home-banner.component';
import { TopDealsComponent } from './top-deals/top-deals.component';
import { DealCategoriesComponent } from './deal-categories/deal-categories.component';
import { DealsOfDayComponent } from './deals-of-day/deals-of-day.component';
import { PopularStoresComponent } from './popular-stores/popular-stores.component';
import { DealsCollectionComponent } from './deals-collection/deals-collection.component';
import { NewsletterComponent } from './newsletter/newsletter.component';
import { PartnerHomePageComponent } from './partner-home-page/partner-home-page';
import { BusinessHeaderComponent } from './partner-header/partner-header.component';
import { BusinessSignUpComponent } from './business-signup/business-signup.component';
import { BusinessSignInComponent } from './business-signin/business-signin.component';
import { BarRatingModule } from 'ngx-bar-rating';
import { AuthCommonModule } from '../auth-common/auth-common.module';
import { CommonModuleCustom } from '../common/common.module';
import { PromotionComponent } from './promotion/promotion.component';
import { BusinessSignupFormComponent } from './business-signup-form/business-signup-form.component';

const routes: Routes = [
  {
    path: '',
    component: AuthComponent,
    children: [{ path: '', component: HomePageComponent }],
  },
];
//{ path: '', redirectTo: '/', pathMatch: 'full' },

@NgModule({
  imports: [
    CarouselModule,
    FormsModule,
    ReactiveFormsModule,
    CommonModule,
    BarRatingModule,
    ThemeModule,
    AuthCommonModule,
    CommonModuleCustom,
    RouterModule.forChild(routes),
  ],
  providers: [],
  exports: [
    AuthComponent,
    PartnerHomePageComponent,
    BusinessSignUpComponent,
    BusinessSignInComponent,
    NewsletterComponent,
  ],
  declarations: [
    AuthComponent,
    HomePageComponent,
    PartnerHomePageComponent,
    HomeBannerComponent,
    TopDealsComponent,
    DealCategoriesComponent,
    DealsOfDayComponent,
    PopularStoresComponent,
    DealsCollectionComponent,
    NewsletterComponent,
    BusinessHeaderComponent,
    BusinessSignUpComponent,
    BusinessSignInComponent,
    PromotionComponent,
    BusinessSignupFormComponent,
  ],
})
export class AuthModule {
  static forRoot(): ModuleWithProviders {
    return {
      ngModule: AuthModule,
      providers: [AuthGuard],
    };
  }
}

declare module '@angular/core' {
  interface ModuleWithProviders<T = any> {
    ngModule: Type<T>;
    provider?: Provider[];
  }
}
