<div class="d-flex flex-column flex-lg-row pt-4 pt-lg-0">
  <div class="left-column me-md-4 mb-3 mb-md-0">
    <div class="image-container">
      <img (error)="selectedCoupon.brandLogo = noImageAvailable"
         src="{{ selectedCoupon.brandLogo }}" alt="Coupon Image" class="img-fluid rounded" />

    </div>
    <a [href]="selectedCoupon.weblink" class="d-block mt-3 text-primary d-flex justify-content-center" target="_blank">
      Go to {{ selectedCoupon.brandName }} website
    </a>

    <div *ngIf="!feedbackGiven" class="feedback mb-3 mt-3 text-center">
      <span>Did the coupon work?</span>
      <div class="d-flex gap-2">
        <button class="btn btn-sm ms-2" (click)="onFeedbackClick()">Yes</button>
        <button class="btn btn-sm" (click)="onFeedbackClick()">No</button>
      </div>
    </div>
    
    <div *ngIf="feedbackGiven" class="feedback mb-3 mt-3 py-2 text-center">
      <span>Thanks for your feedback!</span>
    </div>

    <div class="save-coupon text-center">
      <button class="btn btn-sm btn-primary" (click)="toggleCouponSave(selectedCoupon)" [disabled]="isSaving">
        <span *ngIf="isSaving" class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
        <span *ngIf="!isSaving">
          {{ !isSaved ? "Save this coupon" : "Remove from saved" }}
        </span>
        <span *ngIf="isSaving">Saving...</span>
        <i class="fa fa-heart" *ngIf="!isSaving" [ngClass]="{ 'text-danger': isSaved }"></i>
      </button>
    </div>
  </div>

  <div class="right-column flex-grow-1">
    <div class="text-center">
      <h4 class="brand-name">{{ selectedCoupon.brandName }}</h4>
      <div class="mb-2">
        <div class="feedback mb-3">
          <span class="endDate"> Ends {{ selectedCoupon.validUpTo | date }} </span>
        </div>
      </div>
    </div>

    <p class="mb-3 text-justify">{{ selectedCoupon.longDescription }}</p>

    <div class="coupon-code-section mb-3 p-3">
      <span class="coupon-code">
        <span [ngClass]="{ revealed: isCodeRevealed }" style="word-break: break-all;">
          {{ selectedCoupon.couponCode }}</span>
      </span>
      <button (click)="revealCouponCode(selectedCoupon)" [copy-clipboard]="selectedCoupon.couponCode"
        (copied)="notify($event)" class="btn copy-button ms-2">
        Use this coupon
      </button>

      <pre>{{ status }}</pre>
    </div>

    <ul class="terms list-unstyled">
      <li>✅ {{ selectedCoupon.shortTitle }}</li>
    </ul>
  </div>
</div>

<p class="disclaimer mt-3">
  Deals are offered directly by the retailer and subject to their terms. Local
  deal does not guarantee any deal.
</p>