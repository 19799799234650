<div class="chat-container">  
  <ul class="list-group mt-5">
    <li class="list-group-item bg-gradient-light">
      <div class="row align-items-center justify-content-between">
        <div class="col text-center gradient-text">
          <span *ngIf="!currentMessageThread">Inbox</span>

          <div *ngIf="currentMessageThread" class="d-flex align-items-center gap-3" style="font-size: 17px !important">
            <div class="icon" *ngIf="currentMessageThread">
              <a (click)="deSelectedMessageThread()" class="gradient-text">
                <i class="mt-2 fa fa-chevron-left"></i>
              </a>
            </div>

            <div class="input" *ngIf="currentMessageThread" title="Click to View Deal">
              <a (click)="openDeal(currentMessageThread)">
                {{ currentMessageThread.dealName }}
              </a>
            </div>
          </div>
        </div>
        <button type="button" id="closeChatButton" class="btn-close mr-2" (click)="chatToggle()"></button>
      </div>
    </li>
    <li class="list-group-item" style="border: none">
      <div class="row">
        <div class="col">
          <div class="mainheading">
            <div class="input" *ngIf="!currentMessageThread">
              <input type="text" name="" [(ngModel)]="filterByThreadPerson" (onKeyup)="filterByName()"
              (keyup)="filterByName()" placeholder="Search by name..." id="" class="form-control" />
            </div>
            <!-- <div class="image pl-2">
                          <i class="fa fa-user user-avtar-icon-small"></i>
                      </div> -->
          </div>
        </div>
      </div>
    </li>
    <li class="list-group-item p-0 chat-body">
      <div class="chats" *ngIf="!currentMessageThread">
        <ul class="row">
          <!-- <li class="heading col-12">Direct Messages</li> -->
          <li class="message col-12">
            <ngx-skeleton-loader *ngIf="!msgThreadLoaded && !noMessageFound"
              count="6"
              [theme]="{ 'border-radius': '0', height: '50px', width: 'rand' }"
            ></ngx-skeleton-loader>
            <div class="mesageBox" *ngFor="let msgThread of messageThreads" (click)="selectedMessageThread(msgThread)">
              <div class="image">
                <span style="padding: 10px 15px" class="ratings-author-initial">{{ nameInitial(msgThread.dealName)
                  }}</span>
              </div>
              <div class="text">
                <h6>{{ msgThread.dealName }}</h6>
                <p class="mb-0 {{
                    msgThread.messageConsumerRead ? '' : 'message-unread-state'
                  }}">
                  {{ msgThread.subject }}
                </p>
              </div>
              <div class="unread-dot" *ngIf="unreadMessages[msgThread.messageId]"></div>
            </div>
          </li>
        </ul>
      </div>
      <div class="messageBox no-message" *ngIf="noMessageFound">
        <p>
          No messages Available.
        </p> 
      </div>

      <div class="chats chat-message-thread" *ngIf="currentMessageThread">
        <ngx-skeleton-loader *ngIf="!iscurrentThreadMessagesLoaded"
              count="6"
              [theme]="{ 'border-radius': '0', height: '50px', width: 'rand' }"
            ></ngx-skeleton-loader>
        <ul class="row chat-messages-scrollable" #scrollMe [scrollTop]="scrollMe.scrollHeight">
          <li class="message col-12">
            <div style="width: 100%">
              <div class="chat-message" *ngFor="let message of currentThreadMessages">
                <span class="chat-message-text {{
                    message.consumerName == message.senderName
                      ? 'from-me'
                      : 'to-me'
                  }}">
                  <span>{{ message.message }} </span>
                  <span *ngIf="message.attachmentPath">
                    <a target="_blank" [href]="message.attachmentPath">File</a>
                  </span>
                </span>

                <span class="chat-message-time {{
                    message.consumerName == message.senderName ? 'jp-right' : ''
                  }}" *ngIf="message.created_DT">
                  {{ messageSentTime(message) }}
                </span>
                <div class="chat-message-time {{
                  message.consumerName == message.senderName ? 'jp-right' : ''
                }}" *ngIf="!message.created_DT">
                  <i class="fa fa-clock-o"></i>
                </div>
              </div>
            </div>
          </li>
        </ul>
        <ul class="row chat-message-sender">
          <li class="">
            <form [formGroup]="sendMessageForm" novalidate (ngSubmit)="sendMessage()" class="chat-form">
              <div class="input-container">
                <input formControlName="sendMessageText" placeholder="Type your message here"
                  class="form-control chat-message-input-box" #msgInputBox />
                <input type="file" hidden id="attachment" accept="image/*" (change)="attachmentSelected($event)" />
                <button class="attachment-button" (click)="sendAttachmentInMessage()" type="button">
                  <i class="fa fa-paperclip"></i>
                </button>
                <button class="send-button" type="submit">
                  <i class="fa fa-send"></i>
                </button>
              </div>
            </form>
          </li>
        </ul>
      </div>
    </li>
  </ul>
  
  <!-- <app-http-loader></app-http-loader> -->
</div>