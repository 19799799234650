<section class="top_deals-wrapper">
  <div class="container w-100">
    <div class="row">
      <div class="col-12">
        <h2 class="primary-heading text-center">Local <span class="orange-text">Coupons</span></h2>

        <div class="embla embla-top-deals">
          <div class="embla__viewport">
            <ng-container *ngIf="!isInitialLoad || !isloading; else loadingTemplate">
              <div class="embla__container">
                <ng-container *ngFor="let obj of groupedCoupons; let i = index">
                  <div class="embla__slide">
                    <div class="embla__slide__inner" *ngFor="let coupon of obj">
                      <div class="card">
                        <div style="height: 170px; border-radius: 5px 5px 0 0; object-fit: cover">
                          <img class="card-img-top" [src]="coupon.brandLogo" [alt]="coupon.brandName"
                            (error)="coupon.brandLogo = noImageAvailable"
                            style="height: 100%; object-fit: contain; max-width: 100%" loading="lazy" />
                        </div>
                        <div class="card-body">
                          <div class="card-body-topsection">
                            <span class="card-body-title common-gradient-text w-fit-content single-line-ellipsis">{{
                              coupon.brandName }}</span>
                            <span class="btn-success-green">Exclusive</span>
                          </div>
                          <h5 class="deal-title" style="
                              min-height: 37px;
                              max-height: 37px;
                              overflow: hidden;
                              margin-top: 5px;
                              font-size: 16px;
                              display: -webkit-box;
                              -webkit-line-clamp: 2;
                              -webkit-box-orient: vertical;
                              overflow: hidden;
                              text-overflow: ellipsis;
                              white-space: normal;
                            ">

                            {{ coupon.shortTitle }} - {{ coupon.longDescription }}
                          </h5>
                          <div class="custom-btn-wrapper" style="top: 40px">
                            <button type="button" class="btn custom-btn-sml-orange" title="Get This Coupon"
                              (click)="getThisCoupon(coupon)" [attr.data-toggle]="coupon.couponCode && 'modal'"
                              [attr.data-target]="coupon.couponCode && '#couponModal'">
                              {{
                              coupon.couponCode || !this.loginState
                              ? "Show Coupon Code"
                              : "Get Deal"
                              }}
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </ng-container>

                <ng-container *ngIf="isloading">
                  <div class="embla__slide" *ngFor="let group of [].constructor(2); let i = index">
                    <div class="embla__slide__inner" *ngFor="let item of [].constructor(3); let i = index">
                      <div class="card is-loading">
                        <div style="height: 170px; border-radius: 5px 5px 0 0; object-fit: cover">
                          <img class="card-img-top" style="height: 100%; object-fit: contain; max-width: 100%" />
                        </div>
                        <div class="card-body">
                          <div class="card-body-topsection">
                            <span class="card-body-title"></span>
                          </div>

                          <h5 class="deal-title"
                            style="min-height: 37px; max-height: 37px; overflow: hidden; font-weight: bold; margin-top: 5px; font-size: 16px">
                          </h5>
                          <div class="custom-btn-wrapper" style="top: 40px">
                            <button type="button" class="btn" title="Get This Coupon"></button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </ng-container>
              </div>
            </ng-container>

            <!-- Loading skeleton template -->
            <ng-template #loadingTemplate>
              <div class="embla__container">
                <div class="embla__slide" *ngFor="let group of [].constructor(4); let i = index">
                  <div class="embla__slide__inner" *ngFor="let item of [].constructor(3); let i = index">
                    <div class="card is-loading">
                      <div style="height: 170px; border-radius: 5px 5px 0 0; object-fit: cover">
                        <img class="card-img-top" style="height: 100%; object-fit: contain; max-width: 100%" />
                      </div>
                      <div class="card-body">
                        <div class="card-body-topsection">
                          <span class="card-body-title"></span>
                        </div>

                        <h5 class="deal-title"
                          style="min-height: 37px; max-height: 37px; overflow: hidden; font-weight: bold; margin-top: 5px; font-size: 16px">
                        </h5>
                        <div class="custom-btn-wrapper" style="top: 40px">
                          <button type="button" class="btn" title="Get This Coupon"></button>
                          <!-- <button type="button" class="btn custom-btn-sml-orange" title="Get This Coupon" 
                      (click)="getThisCoupon(coupon)">Show Coupon Code</button> -->
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </ng-template>
          </div>

          <button class="embla__button embla__button--prev" type="button">
            <div class="embla-prev"><i class="fa fa-angle-left" aria-hidden="true"></i></div>
          </button>
          <button class="embla__button embla__button--next" type="button">
            <div class="embla-next"><i class="fa fa-angle-right" aria-hidden="true"></i></div>
          </button>

          <div class="custom-btn-wrapper-lrg">
            <button class="btn custom-btn-large-orange" *ngIf="!isloading">
              <span routerLink="/customer/coupons-browse">View More Coupons</span>
              <div class="icon-wrapper">
                <i class="fa fa-angle-right" aria-hidden="true"></i>
              </div>
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</section>

<div class="modal fade" id="couponModal" tabindex="-1" aria-labelledby="couponModalLabel" aria-hidden="true">
  <div class="modal-dialog modal-dialog-centered modal-lg">
    <div class="modal-content">
      <div class="modal-body position-relative" style="padding: 30px !important">
        <button type="button" class="btn-close position-absolute top-0 end-0 m-4" data-dismiss="modal"
          aria-label="Close" (click)="closePopup()"></button>

        <app-coupon-info [selectedCoupon]="selectedCouponName"></app-coupon-info>
      </div>
    </div>
  </div>
</div>