import { Component, Input, EventEmitter, Output } from '@angular/core';
import { AppState } from 'src/app/core/_store/app.state';
import { ToastrService } from 'ngx-toastr';
import { AppConstants } from 'src/app/core/_utils/appconstants';
import { CouponService } from 'src/app/core/_services/coupon.service';
import { Coupon } from 'src/app/core/_models/coupon';

import Swal from 'sweetalert2';
declare const gtag: Function; // for google analytics

@Component({
  selector: 'app-coupon-info',
  templateUrl: './coupon-info.component.html',
  styleUrls: ['./coupon-info.component.scss'],
})
export class CouponInfoComponent {
  @Input() selectedCoupon: any; // Contains coupon object
  @Input() origin: string; // To identify the origin of the call
  @Output() resetSaved = new EventEmitter<void>();

  isCodeRevealed = false;

  public status: string;
  username: string;
  isSaving = false;

  isSaved: boolean = false;
  noImageAvailable: string;
  feedbackGiven = false;

  constructor(
    private appState: AppState,
    private toastr: ToastrService,
    private couponService: CouponService
  ) {
    this.isSaved = false;
  }

  ngOnInit(): void {
    this.isSaved = false;

    if (this.origin === 'remove') {
      this.isSaved = true;
    }

    if (this.appState.get(AppConstants.USERNAME)) {
      this.username = this.appState.get(AppConstants.USERNAME);
    }
    this.noImageAvailable = '../../../../assets/images/no-image.png';
  }

  revealCouponCode(coupon: Coupon): void {
    this.isCodeRevealed = true;

    let userId = this.appState.get(AppConstants.USER_ID) || 0;

    this.couponService.useCoupon(userId, coupon.id).subscribe((resp) => {
      this.toastr.success(
        'coupon has been used for this account.',
        'Coupon Used',
        { positionClass: 'toast-bottom-right' }
      );
    });

    gtag('event', 'couponcode_view', { coupon_code: coupon.couponCode });
  }

  toggleCouponSave(coupon: Coupon): void {
    if (this.isSaved) {
      this.removeSavedCoupon(coupon.id);
    } else {
      this.saveCoupon(coupon);
    }
  }

  // This service is made for store coupon in 'My Saved Coupons' in profile
  saveCoupon(coupon: Coupon) {
    this.isSaving = true;

    this.couponService
      .saveCustomerCoupon(coupon.id, this.username)
      .subscribe((resp) => {
        this.couponService
          .incrementCouponCountForAnalytics(coupon.id)
          .subscribe((resp) => {});

        this.isSaving = false;
        this.isSaved = true;

        this.toastr.success(
          'This coupon is saved in your "My Coupons" section.',
          'Saved',
          { positionClass: 'toast-bottom-right' }
        );
      });
  }

  // This service is made for remove coupon from 'My Saved Coupons' in profile
  removeSavedCoupon(couponId: number) {
    this.isSaving = true;

    this.couponService
      .deleteCustomerSavedCoupon(couponId, this.username)
      .subscribe((resp) => {
        Swal.fire({
          icon: 'info',
          title: 'Remove this Coupon ?',
          showCloseButton: true,
          showCancelButton: true,
          confirmButtonText: 'Remove',
          confirmButtonColor: '#ff6d41',
        }).then((result) => {
          if (result.isConfirmed) {
            this.isSaving = false;
            this.isSaved = false;

            this.toastr.success(
              'This coupon is deleted from your "My Coupons" section.',
              'Saved',
              { positionClass: 'toast-bottom-right' }
            );
          }
        });
      });
  }

  resetIsSaved(): void {
    this.isSaved = false;
  }

  public notify(event: string): void {
    this.status = `'${event}' has been copied to clipboard`;

    setTimeout(() => {
      this.status = '';
    }, 2000);
  }

  onFeedbackClick() {
    this.feedbackGiven = true;
    setTimeout(() => {
      this.feedbackGiven = false;
    }, 5000);
  }
}
