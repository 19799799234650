import { Component, OnDestroy, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { AppState } from '../../../core/_store/app.state';

import { ConfirmPasswordValidator } from '../../auth-common/confirm-password.validator';
import { RegisterUser } from '../../../core/_models/register.user';

import { AppConstants } from 'src/app/core/_utils/appconstants';
import { RegistrationService } from 'src/app/core/_auth/services/registration.service';
import { ToastrService } from 'ngx-toastr';
import { DealService } from 'src/app/core/_services/partner.service';
import { AbstractControl, ValidatorFn } from '@angular/forms';

@Component({
  selector: 'app-business-signup-form',
  templateUrl: './business-signup-form.component.html',
  styleUrls: ['./business-signup-form.component.scss'],
})
export class BusinessSignupFormComponent implements OnInit, OnDestroy {
  registrationForm: FormGroup;
  // callbackOnRegister: Function;
  isCaptchaSubmitted: boolean;
  isButtonDisabled = true;
  dealCategories: any[];

  loginState: boolean = false;
  showVerifyEmail: boolean = false;

  constructor(
    private fb: FormBuilder,
    private appState: AppState,
    private registrationService: RegistrationService,
    private toastr: ToastrService,
    private dealService: DealService
  ) {}

  ngOnInit(): void {
    if (this.appState.get(AppConstants.USERNAME)) {
      this.loginState = true;
    } else {
      this.loginState = false;
    }
    this.initRegistrationForm();
    this.loadDealCategories();
  }

  loadDealCategories() {
    this.dealService.getDealCategories().subscribe(
      (resp) => {
        this.dealCategories = resp;
      },
      (err) => {
        console.log(err);
      }
    );
  }

  hasError2(controlName: string, validationType: string): boolean {
    const control = this.registrationForm.controls[controlName];
    if (!control) {
      return false;
    }
    const result =
      control.hasError(validationType) && (control.dirty || control.touched);
    return result;
  }

  // setCallbackOnRegister(callbackOnRegister: Function) {
  //   this.callbackOnRegister = callbackOnRegister;
  // }

  agreeTermsAndCondition(event: any) {
    if (event.target.checked) {
      this.isButtonDisabled = false;
    } else {
      this.isButtonDisabled = true;
    }
  }

  callbackOnVerified() {
    // this.verified = true;
    this.showVerifyEmail = false;
    // this.activeTab.login = 'active';
    // this.activeTab.signup = '';
  }

  callbackOnVerifyCancel() {
    // this.showRegOption = true;
    this.showVerifyEmail = false;
    // this.activeTab.login = 'active';
    // this.activeTab.signup = '';
  }

  submit() {
    this.isButtonDisabled = true;

    const controls = this.registrationForm.controls;
    console.warn(controls);
    // check form
    if (this.registrationForm.invalid) {
      console.warn('hitting');
      Object.keys(controls).forEach((controlName) =>
        controls[controlName].markAsTouched()
      );
      return;
    }

    let registerUser: RegisterUser = new RegisterUser();
    registerUser.businessName = controls.businessname.value;
    registerUser.addressLine1 = controls.businessAddress.value;
    registerUser.firstname = controls.firstname.value;
    registerUser.lastname = controls.lastname.value;
    registerUser.email = controls.email.value;
    registerUser.phone = controls.phone.value;
    registerUser.username = controls.username.value;
    registerUser.password = controls.password.value;
    registerUser.businessCategory = controls.businessCategory.value;

    console.log(registerUser);
    // registerUser.partnerType = controls.partnerType.value;

    this.registrationService.registerPartner(registerUser).subscribe(
      (resp) => {
        this.toastr.success('Registration successful', 'SUCCESS');
        this.registrationForm.reset();
        this.isButtonDisabled = false;
        this.appState.set(AppConstants.VERIFY_EMAIL, registerUser.email);
        this.appState.set(AppConstants.VERIFY_USERNAME, registerUser.username);
        // this.router.navigateByUrl('/auth/verify-email');
        // if (this.callbackOnRegister) {
        //   this.callbackOnRegister();
        // }
        this.showVerifyEmail = true;
      },
      (err) => {
        // TODO: handle error
        this.toastr.error(err.error.status, 'ERROR');
        this.isButtonDisabled = false;
      }
    );
  }

  initRegistrationForm() {
    this.registrationForm = this.fb.group(
      {
        businessname: [
          null,
          Validators.compose([
            Validators.required,
            Validators.minLength(3),
            Validators.maxLength(100),
          ]),
        ],
        firstname: [
          null,
          Validators.compose([
            Validators.required,
            Validators.minLength(3),
            Validators.maxLength(100),
          ]),
        ],
        lastname: [
          null,
          Validators.compose([Validators.required, Validators.maxLength(100)]),
        ],
        businessAddress: [
          null,
          Validators.compose([Validators.required, Validators.maxLength(100)]),
        ],
        email: [
          null,
          Validators.compose([
            Validators.required,
            Validators.email,
            Validators.maxLength(320), // https://stackoverflow.com/questions/386294/what-is-the-maximum-length-of-a-valid-email-address
          ]),
        ],
        phone: [
          null,
          Validators.compose([
            Validators.required,
            Validators.minLength(5),
            Validators.maxLength(20),
          ]),
        ],
        username: [
          null,
          Validators.compose([
            Validators.required,
            Validators.minLength(3),
            Validators.maxLength(100),
            noSpaceValidator(),
          ]),
        ],
        password: [
          null,
          Validators.compose([
            Validators.required,
            Validators.minLength(6),
            Validators.maxLength(100),
          ]),
        ],
        confirmPassword: [
          null,
          Validators.compose([
            Validators.required,
            Validators.minLength(6),
            Validators.maxLength(100),
          ]),
        ],
        partnerType: ['WITH_ORDER'],
        businessCategory: ['-1', Validators.compose([
          Validators.required,
          notDefaultValueValidator('-1')
        ])],
      },
      {
        validator: ConfirmPasswordValidator.MatchPassword,
      }
    );
  }

  hasError(controlName: string, validationType: string): boolean {
    const control = this.registrationForm.controls[controlName];
    if (!control) {
      return false;
    }
    const result =
      control.hasError(validationType) && (control.dirty || control.touched);
    return result;
  }

  ngOnDestroy() {}
}

export function noSpaceValidator(): ValidatorFn {
  return (control: AbstractControl): { [key: string]: any } | null => {
    const hasSpace = (control.value || '').includes(' ');
    return hasSpace ? { 'noSpace': true } : null;
  };
}

export function notDefaultValueValidator(defaultValue: any): ValidatorFn {
  return (control: AbstractControl): { [key: string]: any } | null => {
    const isDefaultValue = control.value === defaultValue;
    return isDefaultValue ? { 'defaultValue': true } : null;
  };
}