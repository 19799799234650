<header class="header_main-wrapper" *ngIf="!isLoggedInAsPartner" [ngClass]="{ fixedHeader: isfixedHeader }">
  <!-- First Navbar -->
  <div class="header_main-wrapper-inner">
    <nav class="navbar navbar-expand-lg navbar-dark">
      <div class="container navbar-container">
        <div class="navbar-logoWrap">
          <a class="navbar-brand" *ngIf="login_state" routerLink="/customer" title="Locoldeal">
            <img class="logo" src="assets/images/logos/locoldeal-logo.png" alt="Logo" />
          </a>
          <a class="navbar-brand" *ngIf="!login_state" href="/" title="Locoldeal">
            <img class="logo" src="assets/images/logos/locoldeal-logo.png" alt="Logo" />
          </a>
        </div>
        <div class="navbar-btnsWrap">
          <ng-container *ngIf="login_state && screenWidth <= 991">
            <app-header-customer-profile></app-header-customer-profile>
          </ng-container>
          <ng-container *ngIf="screenWidth <= 991">
            <div class="dropdown search-toggleWrap">
              <button class="dropdown-toggle search-toggle" type="button" id="dropSearchButton" data-toggle="modal"
                data-target="#searchModal" aria-haspopup="true" aria-expanded="false">
                <i class="fa fa-search"></i>
              </button>
              <div class="dropdown-menu" aria-labelledby="dropSearchButton">
                <app-header-search></app-header-search>
              </div>
            </div>
          </ng-container>
          <button class="navbar-toggler" type="button" id="navbarToggler" data-toggle="collapse"
            data-target="#navbarNav" aria-controls="navbarContent" aria-expanded="false" aria-label="Toggle navigation">
            <i class="fa fa-bars ham-icon"></i>
            <i class="fa fa-times close-icon"></i>
          </button>
        </div>

        <ng-container *ngIf="screenWidth > 991">
          <app-header-search></app-header-search>
        </ng-container>

        <div class="header-btnsWrap" *ngIf="!login_state && screenWidth > 991" style="display: flex; gap: 15px">
          <button class="btn custom-btn-white light" type="button" data-toggle="modal" title="Login"
            class="nav-link {{ activeTab.login }}" (click)="openLoginPopup(true)">
            Log in
          </button>

          <button class="btn custom-btn-white light nav-link" id="navbarSignUpDropdown" data-toggle="dropdown"
            aria-haspopup="true" role="button" title="Sign Up">
            Sign Up
          </button>

          <div class="dropdown-menu" aria-labelledby="navbarSignUpDropdown">
            <a class="dropdown-item" type="button" (click)="registerAsCustomer()" data-toggle="modal" title="Sign up"
              #AsUserSignUpButton>
              As a Customer
            </a>
            <a class="dropdown-item" routerLink="/business/signup">
              As a Business
            </a>
          </div>
        </div>

        <ng-container *ngIf="login_state && screenWidth > 991">
          <app-header-customer-profile></app-header-customer-profile>
        </ng-container>
      </div>
    </nav>
  </div>

  <div class="header_sub-wrapper-inner">
    <nav class="navbar navbar-expand-lg navbar-light">
      <div class="collapse navbar-collapse" id="navbarNav">
        <!-- Mobile layout -->
        <ng-container *ngIf="screenWidth <= 991">
          <app-mobile-navigation [categories]="dealCategories" [login_state]="login_state"
            (onSignInClick)="openLoginPopup($event)" (onSignUpClick)="registerAsCustomer()"
            (onLinkClick)="onLinkClick()"></app-mobile-navigation>
        </ng-container>

        <ng-container *ngIf="screenWidth > 991">
          <ul class="navbar-nav">
            <li class="nav-item active">
              <button class="nav-link" id="navbarDropdown" role="button" data-toggle="dropdown"
                data-bs-toggle="dropdown" aria-haspopup="true" aria-expanded="false" title="All"
                (click)="setActiveCategory(-1)">
                <i class="fa fa-bars mr-1"></i> ALL
                <span class="sr-only">(current)</span>
              </button>
              <div class="dropdown-menu" [ngClass]="isActiveCategory(-1) ? 'w-34' : ''" aria-labelledby="navbarDropdown"
                id="navbarDropdownLayover" #navbarDropdownLayover>
                <div class="header_categories-listWrap">
                  <div class="header_categories-listWrap-inner">
                    <ul class="list-group">
                      <li class="list-group-item" [ngClass]="
                          isActiveCategory(1) ? 'activeCategoryTab' : ''
                        ">
                        <a routerLink="/customer/product-search" class="list-group-link"
                          (mouseenter)="showCategoryMenu(1)" title="Deals Of The Day">
                          <span> Local deals and Offers </span>
                        </a>
                      </li>
                      <li class="list-group-item" [ngClass]="
                          isActiveCategory(2) ? 'activeCategoryTab' : ''
                        ">
                        <a routerLink="/customer/coupons-browse" class="list-group-link" title="Coupons"
                          (mouseenter)="showCategoryMenu(2)">
                          <span> Coupons </span>
                        </a>
                      </li>
                    </ul>
                  </div>

                  <div class="header_categories-listWrap-inner" *ngIf="selectedContent === 1">
                    <div class="d-flex body-top-wrapper">
                      <h5>Local deals and offers</h5>
                    </div>

                    <ul class="header_categories-list" id="catListUL">
                      <li *ngFor="let cat of dealCategories" class="header_categories-item">
                        <a class="header_categories-link" (click)="
                            navigateToSearch(selectedContent, cat.categoryName)
                          " title="{{ cat.categoryName }}">
                          <span>
                            {{ cat.categoryName }}
                          </span>
                        </a>
                      </li>
                    </ul>
                  </div>

                  <div class="header_categories-listWrap-inner" *ngIf="selectedContent === 2">
                    <div class="d-flex body-top-wrapper">
                      <h5>Coupons</h5>
                    </div>

                    <ul class="header_categories-list" id="catListUL">
                      <li *ngFor="let cat of dealCategories" class="header_categories-item">
                        <a class="header_categories-link" (click)="
                            navigateToSearch(selectedContent, cat.categoryName)
                          " title="{{ cat.categoryName }}">
                          <span>
                            {{ cat.categoryName }}
                          </span>
                        </a>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </li>
            <li class="nav-item">
              <a class="nav-link" routerLink="/customer/product-search">Deals Of The Day</a>
            </li>
            <li class="nav-item">
              <a class="nav-link" routerLink="/customer/store-list">Stores</a>
            </li>
            <li class="nav-item">
              <a class="nav-link" routerLink="/customer/coupons-browse">Coupons</a>
            </li>
            <li class="nav-item" *ngIf="!login_state">
              <a class="nav-link" routerLink="/business/signin">Business login</a>
            </li>
            <li class="nav-item">
              <a class="nav-link" href="https://locoldeal.com/blog">Blog</a>
            </li>
          </ul>
        </ng-container>
      </div>
    </nav>
  </div>
</header>

<!--Modal: Login Form-->
<div class="modal fade bd-example-modal-lg login_signup-modal" id="panel7" tabindex="-1" role="dialog" #modalLoginForm>
  <div class="modal-dialog modal-lg modal-centered" role="document">
    <!--Content-->
    <div class="modal-content">
      <div style="flex: 1">
        <div class="modal-header" style="
            display: flex;
            justify-content: center;
            align-items: center;
            position: relative;
            text-align: center;
            flex-direction: column;
            width: 100%;
          ">
          <h2 class="modal-title common-gradient-text w-fit-content" id="myModalSignUpLabel" style="margin: 0">
            Welcome to Locoldeal!
          </h2>

          <button type="button" class="btn-close btn-close-dark" data-dismiss="modal" aria-label="Close"
            style="position: absolute; right: 20px; top: 25px" (click)="openLoginPopup(false)" #closeModal></button>

          <p style="color: gray !important; margin: 10px 0 0; font-size: 18px">
            Elevate your shopping experience with
            <br />
            the best offers around
          </p>
        </div>

        <!--Modal cascading tabs-->
        <div class="modal-c-tabs">
          <!-- Tab panels -->
          <div class="tab-content">
            <div class="tab-pane {{ activeTab.login }}" id="panel7" role="tabpanel">
              <!--Body-->
              <div class="modal-body mb-1">
                <!-- LOGIN FORM -->
                <div [hidden]="showResetPwd">
                  <app-login (onForgotPwdClick)="onForgotPwdClick()" (onLoginSuccess)="onLoginSuccess($event)"
                    (signUpButtonEvent)="signUpButtonClickEvent($event)"></app-login>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<!--Modal: Register Form-->
<div class="modal fade bd-example-modal-lg login_signup-modal" id="modalSignupForm" tabindex="-1" role="dialog"
  #modalSignUpForm>
  <div class="modal-dialog modal-lg modal-centered" role="document">
    <div class="modal-content">
      <div style="flex: 1">
        <div class="modal-header">
          <h2 class="modal-title common-gradient-text w-fit-content" id="myModalSignUpLabel">
            {{ showVerifyEmail ? "Verify Email" : "Sign Up" }}
          </h2>

          <button type="button" class="btn-close btn-close-dark" data-dismiss="modal" aria-label="Close"
            (click)="openSignupPopup(false)"></button>
        </div>

        <div class="modal-c-tabs">
          <div class="tab-content">
            <div id="panel7" role="tabpanel">
              <div [hidden]="showVerifyEmail" class="modal-body mb-1">
                <app-register-customer></app-register-customer>
              </div>
              <div *ngIf="showVerifyEmail" class="modal-body mb-1">
                <app-verify-email (onVerifySuccess)="callbackOnVerified()"
                  (onVerifyCancelled)="callbackOnVerifyCancel()"></app-verify-email>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<!--Modal: Forgot Password Form-->
<div class="modal fade bd-example-modal-lg login_signup-modal" id="forgotPassword" tabindex="-1" role="dialog"
  #forgotPasswordModal>
  <div class="modal-dialog modal-lg modal-centered" role="document">
    <!--Content-->
    <div class="modal-content">
      <div style="flex: 1">
        <div class="modal-header" style="
            display: flex;
            justify-content: center;
            align-items: center;
            position: relative;
            text-align: justify;
            flex-direction: column;
            width: 100%;
          ">
          <h2 *ngIf="currentPage === 'email'" class="modal-title common-gradient-text w-fit-content"
            id="myModalSignUpLabel" style="margin: 0">
            Forgot Password ?
          </h2>

          <h2 *ngIf="currentPage === 'code'" class="modal-title common-gradient-text w-fit-content"
            id="myModalSignUpLabel" style="margin: 0">
            Reset your Password
          </h2>

          <h2 *ngIf="currentPage === 'newPassword'" class="modal-title common-gradient-text w-fit-content"
            id="myModalSignUpLabel" style="margin: 0">
            Choose a new Password
          </h2>

          <img class="logo" src="assets/images/Login/forgotPasswordPageImage.png" alt="Logo"
            *ngIf="currentPage === 'email'" style="width: 80px; height: 80px; margin: 10px" />

          <img class="logo" src="assets/images/Login/resettPasswordPageImage.png" alt="Logo"
            *ngIf="currentPage === 'code'" style="width: 80px; height: 80px; margin: 10px" />

          <img class="logo" src="assets/images/Login/newPasswordPageImage.png" alt="Logo"
            *ngIf="currentPage === 'newPassword'" style="width: 80px; height: 80px; margin: 10px" />

          <button type="button" class="btn-close btn-close-dark" data-dismiss="modal" aria-label="Close"
            style="position: absolute; right: 15px; top: 10px" (click)="openresetPwdPopup(false)"></button>

          <p *ngIf="currentPage === 'email'" style="
              color: gray !important;
              margin: 10px 0 0;
              font-size: 18px;
              text-align: justify;
              padding: 0px 10px 0px 24px;
            ">
            Please enter the email associated with your account. If an account
            exists, we will send your username and a password reset code to your
            email inbox.
          </p>

          <p *ngIf="currentPage === 'code'" style="
              color: gray !important;
              margin: 10px 0 0;
              font-size: 18px;
              text-align: justify;
              padding: 0px 10px 0px 24px;
            ">
            We've emailed your username and a verification code in your email.
            Enter the code below to reset your password.
          </p>

          <p *ngIf="currentPage === 'newPassword'" style="
              color: gray !important;
              margin: 10px 0 0;
              font-size: 18px;
              text-align: justify;
              padding: 0px 10px 0px 24px;
            ">
            All done ! Enter your new password and your ready to go.
          </p>
        </div>

        <div class="modal-c-tabs">
          <!-- Tab panels -->
          <div class="tab-content">
            <div class="tab-pane {{ activeTab.login }}" id="forgotPassword" role="tabpanel">
              <!--Body-->
              <div class="modal-body mb-4">
                <div>
                  <app-reset-password (onCancel)="onResetPwdCancel()" [currentPage]="currentPage"
                    (currentPageChange)="onCurrentPageChange($event)"
                    (resetPasswordToRedirect)="resetPasswordToRedirect()"></app-reset-password>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<!-- Search Modal Starts -->

<!-- Chat Window -->
<ng-container *ngIf="login_state && chatToggle">
  <app-customer-chat (close)="onChatClose($event)"></app-customer-chat>
</ng-container>

<ng-container *ngIf="login_state && notifWindowToggle">
  <app-common-notifications></app-common-notifications>
</ng-container>

<ng-container>
  <app-common-search-page></app-common-search-page>
</ng-container>