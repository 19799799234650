import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { CustomerChatComponent } from './customer-chat/customer-chat.component';
import { LayoutService } from './layout.service';
import { ScrollToBottomDirective } from './scroll-to-bottom.directive';
import { AppHttpLoaderComponent } from './app-http-loader/app-http-loader.component';
import { CommonNotificationsComponent } from './common-notifications/common-notifications.component';
import { SearchPageComponent } from './common-search-page/app-common-search-page';
import { NgxSkeletonLoaderModule } from 'ngx-skeleton-loader';
import { ErrorComponent } from './error/error.component';
import { CouponInfoComponent } from '../customer/coupon-info/coupon-info.component';
import { CopyClipboardModule } from '../common/copy-to-clipboard/copy-clipboard.module';

@NgModule({
  declarations: [
    ScrollToBottomDirective,
    CustomerChatComponent,
    AppHttpLoaderComponent,
    CommonNotificationsComponent,
    SearchPageComponent,
    ErrorComponent,
    CouponInfoComponent,
  ],
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    NgxSkeletonLoaderModule,
    CopyClipboardModule,
  ],
  exports: [
    CustomerChatComponent,
    AppHttpLoaderComponent,
    CommonNotificationsComponent,
    SearchPageComponent,
    CouponInfoComponent,
  ],
  providers: [LayoutService],
})
export class CommonModuleCustom {}
