import {
  Component,
  OnInit,
  OnDestroy,
  Output,
  EventEmitter,
} from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { AppState } from '../../../core/_store/app.state';
import { AppConstants } from 'src/app/core/_utils/appconstants';
import { AuthService } from '../../../core/_auth/services/auth.service';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-verify-email',
  templateUrl: './verify.email.component.html',
  styleUrls: ['./verify.email.component.scss'],
})
export class VerifyEmailComponent implements OnInit, OnDestroy {
  @Output('onVerifySuccess') verifySuccessEvent = new EventEmitter<boolean>();
  @Output('onVerifyCancelled') verifyCancelledEvent =
    new EventEmitter<boolean>();

  verifyEmailForm: FormGroup;
  user: User;

  constructor(
    private appState: AppState,
    private fb: FormBuilder,
    private authService: AuthService,
    private toastr: ToastrService
  ) {}

  ngOnInit(): void {
    this.user = new User();
    this.user.email = this.appState.get(AppConstants.VERIFY_EMAIL);
    this.user.username = this.appState.get(AppConstants.VERIFY_USERNAME);
    this.initEmailVerifyForm(this.user.email);
    this.sendVerificationEmail();

    console.log(this.user.email, this.user.username);
  }

  onEmail() {
    this.user.email = this.verifyEmailForm.controls.email.value;
    this.user.username = this.appState.get(AppConstants.VERIFY_USERNAME);
    this.toastr.info('Sending verification code on Email...', 'INFO');
    this.sendVerificationEmail();
  }

  sendVerificationEmail() {
    if (this.user.email && this.user.username) {
      this.authService
        .sendCodeEmailVerify(this.user.username, this.user.email)
        .subscribe(
          (resp) => {
            this.toastr.success('Verification Code sent over email', 'SUCCESS');
            console.log('sent code for email verification...');
          },
          (err) => {
            this.toastr.error(
              'Failed to send verification code over email',
              'ERROR'
            );
            console.log('sending code to email failed..');
          }
        );
    } else {
      // this.router.navigateByUrl('/');
    }
  }

  submit() {
    const controls = this.verifyEmailForm.controls;
    // check form
    if (this.verifyEmailForm.invalid) {
      Object.keys(controls).forEach((controlName) =>
        controls[controlName].markAsTouched()
      );
      return;
    }
    this.authService
      .verifyCodeEmailVerify(this.user.username, controls.code.value)
      .subscribe(
        (resp) => {
          console.log('email verification done');
          this.toastr.success('Email verification successful', 'SUCCESS');
          // this.router.navigateByUrl('/');
          this.verifySuccessEvent.emit(true);
        },
        (err) => {
          this.toastr.error('Verification code does not match', 'ERROR');
          console.log('error on verify email', err);
        }
      );
  }

  cancel() {
    this.verifyCancelledEvent.emit(true);
  }

  hasError(controlName: string, validationType: string): boolean {
    const control = this.verifyEmailForm.controls[controlName];
    if (!control) {
      return false;
    }
    const result =
      control.hasError(validationType) && (control.dirty || control.touched);
    return result;
  }

  initEmailVerifyForm(email: string) {
    this.verifyEmailForm = this.fb.group({
      code: [null, Validators.compose([Validators.required, Validators.maxLength(6)])],
      email: [email],
    });
  }

  ngOnDestroy(): void {
    this.user = null;
  }
}

export class User {
  email: string;
  username: string;
  twoFaCode: string;
}
